import { FC } from 'react'
import { useDevSettings } from 'common/services/useDevSettings'
import Head from 'next/head'
import { Project } from 'common/project'

type MetaType = {}

const Meta: FC<MetaType> = ({}) => {
  const { devSettings } = useDevSettings()
  const whitelabel = devSettings.whitelabel
  const whitelabelLoaded = devSettings.whiteLabelLoaded
  const relativePath = whitelabel ? `${whitelabel}/` : ''

  return (
    <Head>
      <meta charSet='utf-8' />
      <meta httpEquiv='x-ua-compatible' content='ie=edge' />
      <meta name='description' content='HM Portal' />
      <meta name='theme-color' content='#317EFB' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, shrink-to-fit=no'
      />
      <meta name='theme-color' content='#ffffff' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1.0, maximum-scale=1.0'
      />
      <meta property='fb:admins' content='453323778023845' />
      {whitelabelLoaded && (
        <>
          <link
            rel='apple-touch-icon'
            sizes='180x180'
            href={`/${relativePath}apple-touch-icon.png`}
          />
          <link
            rel='icon'
            type='image/png'
            sizes='32x32'
            href={`/${relativePath}favicon-32x32.png`}
          />
          <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href={`/${relativePath}favicon-16x16.png`}
          />
          <meta property='og:title' content={Project.siteName} />
        </>
      )}
    </Head>
  )
}

export default Meta
