import Link from 'next/link'
import dataRelay from 'data-relay'
import classNames from 'classnames'
import { Collapse } from 'reactstrap'
import { useFlags } from 'flagsmith/react'
import Router, { useRouter } from 'next/router'
import { FC, ReactNode, useEffect, useState } from 'react'

import Logo from 'components/Logo'
import cognito from 'common/cognito'
import { Project } from 'common/project'
import Icon, { IconName } from 'project/Icon'
import Tooltip from 'components/base/Tooltip'
import Button from 'components/base/forms/Button'
import { useUserData } from 'common/services/useUserData'
import { useLogoutMutation, useUser } from 'common/services/useUser'
import { useGetMailCountsQuery } from 'common/services/useMailCount'
import { useGetKycMembersQuery } from 'common/services/useKycMember'
import ActiveLink, { ActiveLinkType } from 'components/base/ActiveLink'
import { PlanSlug, productLinks } from 'components/project/WebsiteFooter'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'

type AsideType = {}

type AsideNavItemType = {
  children?: ReactNode
  icon?: ReactNode
  className?: string
  active?: boolean
}

const AsideNavItem: FC<AsideNavItemType> = ({
  active,
  children,
  className,
  icon,
}) => {
  return (
    <div
      className={classNames(
        'flex-row rounded animated aside__item fw-semibold aside__item cursor-pointer align-items-center',
        { 'active': active },
        className,
      )}
    >
      <div className='icon-container me-2'>
        {typeof icon === 'string' ? <span className={icon} /> : icon}
      </div>

      <div className='flex-1 content text-nowrap no-animation'>{children}</div>
    </div>
  )
}

const SidebarGroup: FC<
  AsideNavItemType & ActiveLinkType & { label: string }
> = ({ children, ...props }) => {
  const router = useRouter()
  const isOpen = !!router.asPath.match(props.match)
  return (
    <div className='d-flex flex-column gap-4'>
      <ActiveLink href={props.href} activeClassName='active'>
        <div>
          <AsideNavItem {...props}>{props.label}</AsideNavItem>
        </div>
      </ActiveLink>
      <Collapse isOpen={isOpen}>
        <ul className='flex-column collapse show nav ps-4'>{children}</ul>
      </Collapse>
    </div>
  )
}

const TooltipIcon: FC<{
  name: IconName
  content: string
  showTooltip: boolean
}> = ({ content, name, showTooltip }) => {
  if (showTooltip) {
    return (
      <Tooltip place='right' content={content}>
        <Icon fill='body' name={name} />
      </Tooltip>
    )
  } else {
    return <Icon fill='body' name={name} />
  }
}

const Aside: FC<AsideType> = ({}) => {
  const router = useRouter()
  const activePath = router.pathname
  const { data: user } = useUser({})
  const { userData } = useUserData()
  const [logout] = useLogoutMutation({})
  const [collapsed, setCollapsed] = useState(true)
  const { defaultSubscriptionId, hasPermission, subscriptionDetail } =
    useDefaultSubscription()
  const {
    data: kycMembers,
    isUninitialized,
    refetch,
  } = useGetKycMembersQuery(
    { subscription: `${defaultSubscriptionId}` },
    { refetchOnMountOrArgChange: true, skip: !defaultSubscriptionId },
  )
  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    if (!collapsed) {
      window.scrollTo({ top: 0 })
    }
  }, [collapsed])

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      setCollapsed(true)
    })
  }, [])

  const { data: unread } = useGetMailCountsQuery(
    // @ts-ignore
    { subscription_id: defaultSubscriptionId },
    { skip: !defaultSubscriptionId },
  )

  useEffect(() => {
    if (!collapsed) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [collapsed])

  useEffect(() => {
    if (!expanded) {
      document.body.classList.add('aside-collapsed')
    } else {
      document.body.classList.remove('aside-collapsed')
    }
  }, [expanded])

  useEffect(() => {
    if (!collapsed) {
      window.scrollTo({ top: 0 })
    }
  }, [collapsed])

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      setCollapsed(true)
    })
  }, [])

  const {
    devmode,
    feature_business,
    feature_kyc,
    feature_marketplace,
    feature_teams,
    start_another_business,
  } = useFlags([
    'devmode',
    'feature_business',
    'feature_kyc',
    'feature_marketplace',
    'feature_teams',
    'start_another_business',
  ])

  const kycCompletedLegacy =
    subscriptionDetail?.kyc_status?.kyc_completed &&
    kycMembers?.results?.length === 0

  const checkout = () => {
    if (!user) {
      return
    }
    const data = {
      'address': {
        'address_line_1': '',
        'address_line_2': '',
        'city': '',
        'country': '',
        'postal_code': '',
        ...(subscriptionDetail?.address || {}),
        'first_name': userData?.first_name || '',
        'last_name': userData?.last_name || '',
      },
      'billing_address': {
        'address_line_1': '',
        'address_line_2': '',
        'city': '',
        'country': '',
        'postal_code': '',
        ...(subscriptionDetail?.address || {}),
        'first_name': userData?.first_name || '',
        'last_name': userData?.last_name || '',
      },
      'business': {
        'business_type': 'LIMITED_COMPANY',
        'company_number': '',
        'country_of_corporation': 'GB',
        'description': '',
        'legal_name': '',
        'trading_name': '',
      },
      'customer_email': user?.username || '',
      'first_name': userData?.first_name || '',
      'last_name': userData?.last_name || '',
      'payment_type': '',
      'phone_number': '',
      'product_id': '',
      'reference_id': '',
    }
    const links = productLinks()
    const link = links.find((v) => {
      return v.title.includes(subscriptionDetail?.name)
    })
    const foundSlug = Object.values(PlanSlug).find((v) =>
      link?.href.includes(v),
    )
    const cookieJSON: Record<string, string> = {
      referrer: user.username,
    }

    if (start_another_business.value) {
      cookieJSON.coupon = `${start_another_business.value}`
    }
    if (foundSlug) {
      data.product_id = foundSlug
      cookieJSON.bag = JSON.stringify({
        'addons': [],
        'duration': 'month',
        'slug': foundSlug,
      })
    }
    if (Project.dataRelay) {
      try {
        dataRelay.sendEvent(
          {
            company: data.business.legal_name || data.business.trading_name,
            email: data.customer_email,
            event: 'Second business click',
            product: data.product_id,
          },
          { apiKey: Project.dataRelay },
        )
      } catch (e) {}
    }
    cookieJSON.checkout = JSON.stringify(data)
    const cookieUrl = encodeURIComponent(btoa(JSON.stringify(cookieJSON)))
    if (foundSlug) {
      window.open(
        `${Project.marketingSiteUrl}/checkout?c=${cookieUrl}`,
        '_blank',
      )
    } else {
      window.open(`${Project.marketingSiteUrl}?c=${cookieUrl}`, '_blank')
    }
  }

  return (
    <div
      className={classNames('aside-container aside-fixed animated', {
        expanded,
        open: !collapsed,
      })}
    >
      <div className='d-flex px-5 py-2 d-md-none nav align-items-center justify-content-between'>
        <button
          onClick={() => {
            setCollapsed(!collapsed)
          }}
          className='d-md-none navbar-toggler'
          type='button'
        >
          {!collapsed ? (
            <Icon fill={'body'} width={24} height={16} name='close' />
          ) : (
            <Icon fill={'body'} width={24} height={24} name='hamburger-menu' />
          )}
        </button>
        <Logo collapsed={!expanded} />
        <div />
      </div>
      <div
        className={classNames('aside h-100 w-100 d-flex flex-column py-8', {
          'd-none d-md-flex flex-column': collapsed,
        })}
      >
        <div className='flex-1'>
          <Link href={'/'}>
            <div
              style={{ marginLeft: -3, width: 230 }}
              className='px-5 overflow-hidden d-none d-md-block mt-0 mb-6 cursor-pointer'
            >
              <Logo collapsed={!expanded}>
                <h1 className='text-body mb-0'>Mailbox</h1>
              </Logo>
            </div>
          </Link>
          <div className={classNames('gap-4 px-5 d-flex flex-column gap-2')}>
            <ActiveLink href={`/`} activeClassName='active'>
              <div>
                <AsideNavItem
                  icon={
                    <TooltipIcon
                      showTooltip={!expanded}
                      name='dashboard'
                      content={'Dashboard'}
                    />
                  }
                >
                  Dashboard
                </AsideNavItem>
              </div>
            </ActiveLink>
            {hasPermission('READ_MAIL') && (
              <ActiveLink
                match={'/mail/table'}
                href={`/mail`}
                activeClassName='active'
              >
                <div>
                  <AsideNavItem
                    icon={
                      <TooltipIcon
                        showTooltip={!expanded}
                        name='mail'
                        content={'Mail'}
                      />
                    }
                  >
                    Mail
                  </AsideNavItem>
                </div>
              </ActiveLink>
            )}

            {!subscriptionDetail?.is_partner_customer &&
              hasPermission('READ_INVOICE') && (
                <ActiveLink
                  href={`/account-billing`}
                  match={'/account'}
                  activeClassName='active'
                >
                  <div>
                    <AsideNavItem
                      icon={
                        <TooltipIcon
                          showTooltip={!expanded}
                          name='account'
                          content={'Account & Billing'}
                        />
                      }
                    >
                      Account & Billing
                    </AsideNavItem>
                  </div>
                </ActiveLink>
              )}
            {!subscriptionDetail?.is_partner_customer && (
              <>
                {!kycCompletedLegacy &&
                feature_kyc.enabled &&
                hasPermission('READ_MEMBER') &&
                !subscriptionDetail?.kyc_status?.kyc_link ? (
                  <SidebarGroup
                    icon={
                      <TooltipIcon
                        showTooltip={!expanded}
                        name='business-settings'
                        content={'Business Settings'}
                      />
                    }
                    label='Business Settings'
                    href='/business'
                    match={/business/}
                  >
                    <ActiveLink
                      href={`/business/owners`}
                      activeClassName='active'
                    >
                      <div>
                        <AsideNavItem
                          className='aside__subitem'
                          icon={
                            !expanded ? (
                              <TooltipIcon
                                showTooltip={!expanded}
                                name='verify'
                                content={'Verify'}
                              />
                            ) : undefined
                          }
                        >
                          <div className='d-flex align-items-center justify-content-between'>
                            Verify
                            <span className='fa fa-chevron-right fs-lg pe-4' />
                          </div>
                        </AsideNavItem>
                      </div>
                    </ActiveLink>
                    {/*<SidebarItem*/}
                    {/*  size='sm'*/}
                    {/*  label='Business Directors'*/}
                    {/*  path='/business/directors/'*/}
                    {/*  activePath={activePath}*/}
                    {/*/>*/}
                  </SidebarGroup>
                ) : (
                  feature_business.enabled && (
                    <ActiveLink href={`/business`} activeClassName='active'>
                      <div>
                        <AsideNavItem
                          icon={
                            <TooltipIcon
                              showTooltip={!expanded}
                              name='business-settings'
                              content={'Business Settings'}
                            />
                          }
                        >
                          Business Settings
                        </AsideNavItem>
                      </div>
                    </ActiveLink>
                  )
                )}
              </>
            )}
            {feature_marketplace.enabled && (
              <ActiveLink
                match={/marketplace/}
                href={`/marketplace/`}
                activeClassName='active'
              >
                <div>
                  <AsideNavItem
                    icon={
                      <TooltipIcon
                        showTooltip={!expanded}
                        name='market'
                        content={'Market'}
                      />
                    }
                  >
                    Marketplace
                  </AsideNavItem>
                </div>
              </ActiveLink>
            )}

            {feature_teams.enabled && hasPermission('READ_MEMBER') && (
              <ActiveLink
                match={/team/}
                href={`/team/`}
                activeClassName='active'
              >
                <div>
                  <AsideNavItem
                    icon={
                      <TooltipIcon
                        showTooltip={!expanded}
                        name='team'
                        content={'Team'}
                      />
                    }
                  >
                    Team
                  </AsideNavItem>
                </div>
              </ActiveLink>
            )}
            {Project.homeLinks?.map((homeLink) => (
              <a target='_blank' rel='noopener noreferrer' href={homeLink.url}>
                <div>
                  <AsideNavItem icon={<Icon fill='body' name='link' />}>
                    {homeLink.label}
                  </AsideNavItem>
                </div>
              </a>
            ))}
            {devmode.enabled && (
              <ActiveLink
                match={/developer-settings/}
                href={`/developer-settings/`}
                activeClassName='active'
              >
                <div>
                  <AsideNavItem
                    icon={
                      <TooltipIcon
                        showTooltip={!expanded}
                        name='code'
                        content={'Developer Settings'}
                      />
                    }
                  >
                    Developer Settings
                  </AsideNavItem>
                </div>
              </ActiveLink>
            )}
          </div>
        </div>
        <div className='px-5'>
          {start_another_business.enabled && (
            <div className='rounded-3 p-4 mb-8 d-flex flex-column gap-4 p-2 bg-action text-white text-center'>
              <h4 className='mb-0'>Have another business?</h4>
              <div className='fs-sm px-2'>
                Sign up with our quick registration process and enjoy 20% off
                your first year.
              </div>
              <Button onClick={checkout} theme='white'>
                Sign up
              </Button>
            </div>
          )}
          <div
            onClick={() => {
              router.replace('/')
              cognito.logout().finally(() => {
                logout({})
              })
            }}
          >
            <AsideNavItem
              icon={
                <TooltipIcon
                  showTooltip={!expanded}
                  name='logout'
                  content={'Logout'}
                />
              }
            >
              Logout
            </AsideNavItem>
          </div>
          <div
            onClick={() => setExpanded(!expanded)}
            className='d-none cursor-pointer d-md-flex'
          >
            <svg
              className={classNames('mt-3 animate')}
              width='34'
              height='34'
              viewBox='0 0 34 34'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                className='hover-light400 bg-light300'
                width='34'
                height='34'
                rx='8'
              />
              <g
                className={classNames('animated transform-center', {
                  rotate180: expanded,
                })}
              >
                <path
                  d='M15.9367 11.2438L21.3295 16.5923C21.3835 16.6454 21.4264 16.7088 21.4557 16.7788C21.4849 16.8487 21.5 16.9238 21.5 16.9997C21.5 17.0756 21.4849 17.1507 21.4557 17.2206C21.4264 17.2906 21.3835 17.354 21.3295 17.4071L15.9367 22.7556C15.7779 22.9122 15.5641 23 15.3414 23C15.1187 23 14.905 22.9122 14.7461 22.7556C14.6682 22.6786 14.6063 22.5867 14.564 22.4854C14.5218 22.3841 14.5 22.2754 14.5 22.1656C14.5 22.0558 14.5218 21.947 14.564 21.8457C14.6063 21.7444 14.6682 21.6526 14.7461 21.5755L19.3597 16.9997L14.7461 12.4251C14.6682 12.3479 14.6064 12.256 14.5642 12.1546C14.5219 12.0533 14.5002 11.9446 14.5002 11.8347C14.5002 11.7249 14.5219 11.6161 14.5642 11.5148C14.6064 11.4135 14.6682 11.3215 14.7461 11.2444C14.905 11.0878 15.1187 11 15.3414 11C15.5641 11 15.7779 11.0872 15.9367 11.2438Z'
                  fill='#6E6E73'
                />
                <path
                  d='M13.0262 12.6828L16.8782 16.6942C16.9168 16.7341 16.9474 16.7816 16.9683 16.8341C16.9892 16.8865 17 16.9429 17 16.9998C17 17.0567 16.9892 17.113 16.9683 17.1655C16.9474 17.2179 16.9168 17.2655 16.8782 17.3053L13.0262 21.3167C12.9128 21.4342 12.7601 21.5 12.601 21.5C12.442 21.5 12.2893 21.4342 12.1758 21.3167C12.1201 21.2589 12.0759 21.19 12.0457 21.114C12.0155 21.0381 12 20.9565 12 20.8742C12 20.7918 12.0155 20.7103 12.0457 20.6343C12.0759 20.5583 12.1201 20.4894 12.1758 20.4316L15.4712 16.9998L12.1758 13.5688C12.1202 13.5109 12.076 13.442 12.0458 13.366C12.0157 13.29 12.0001 13.2084 12.0001 13.126C12.0001 13.0437 12.0157 12.9621 12.0458 12.8861C12.076 12.8101 12.1202 12.7412 12.1758 12.6833C12.2893 12.5658 12.442 12.5 12.601 12.5C12.7601 12.5 12.9128 12.5654 13.0262 12.6828Z'
                  fill='#6E6E73'
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Aside
